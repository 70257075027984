import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';

 

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';



import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';


import config from './appenvdata.json';




Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



export function MainComponent() {

  

  return (
    <div >
      <h2 class="heading">AutomateMM</h2>
    
      
    <Container>

     

     
      <div class="product-description">
      AutomateMM offers a centralised repository for all automation assets and scripts across APJ, allowing for easy access and utilisation. One of its key features is the ability to track the usage of these assets, enabling us to measure their value in terms of human hours saved and the speed of delivery. 
By automating repetitive tasks, AutomateMM significantly enhances the efficiency and productivity of MMSAs and Non-MMSA's.
</div>
<div class="product-description">
<p><a href="https://quip-amazon.com/qxC2AoiOEs4y/Automate-MM-V2" target="_blank" > About the Platform  </a></p>
<p><a href="https://quip-amazon.com/rQlBATUb4DJY/AutomateMM-New-Asset-Request" target="_blank" > New Asset Request</a></p>
<p><a href="https://quip-amazon.com/LHQvA3o9EsD2/AutomateMM-Feedback" target="_blank" >Feedback</a></p>
<p>Contact us on Slack : <a target="_blank"  href="https://amazon.enterprise.slack.com/archives/C07ARSBDPE3">#automatemm-support</a></p>


</div>


     
  
 
</Container>

</div>
  );
}

export default MainComponent;
